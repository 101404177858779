<template>
  <div class="flex flex-col gap-6">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize border border-dark-100 rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div>
        <h2 class="md:text-lg">Video Verification</h2>
      </div>
      <div>
        <div>
          <slot />
        </div>
      </div>
    </div>

    <div
      class="border border-dark-100 rounded-3xl p-4 flex flex-col gap-4 md:gap-6 text-xs md:text-sm"
    >
      <div>
        Record a 10 seconds video of yourself saying your name, why you’re
        opening an Eazipay account and where you live.. e.g
        <span class="text-blue-500">
          “ hello my name is ….. , i am opening an Eazipay account today  …. I
          live at …….)</span
        >
      </div>
      <div>
        <span class="text-error font-bold leading-6">Important Notice</span>
        <p>
          To verify, record a well-lit, in-focus video of your face. Accepted
          formats: AVI, MP4, WEBM, up to 20MB. Record within the highlighted
          area and re-upload if necessary.
        </p>
      </div>

      <div v-if="!hasData" class="w-full md:w-72">
        <UploadComponent
          dotted
          capture
          @fileUrl="uploadFile = $event"
          :clear="removeFile"
        >
          <template #dotted>
            <div class="text-xs sm:text-sm flex items-center justify-between">
              <span>Record Video</span>
              <img src="@/assets/icons/videoIcon.svg" alt="" />
            </div>
          </template>
        </UploadComponent>
      </div>

      <div
        v-else
        class="py-2 px-4 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <div class="h-10 w-10 bg-white rounded-full"></div>
          <div class="flex flex-col">
            <span class="">My Verification video (.mov) </span>
            <span class="text-dark-400 text-xs">20mb</span>
          </div>
        </div>

        <GetStatus bgWhite status="Verified" />
      </div>
    </div>

    <div
      v-if="!hasData"
      class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
    >
      <easiButton
        :block="breakPoints('md')"
        variant="text"
        color="secondary"
        size="medium"
        >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
      >

      <div class="flex flex-col md:flex-row md:items-center md:gap-4">
        <easiButton variant="outlined" size="medium" class="hidden md:flex"
          >Save Update</easiButton
        >
        <easiButton :block="breakPoints('md')" size="medium">Submit</easiButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import GetStatus from "../GetStatus.vue";
import UploadComponent from "@/components/global/UploadComponent";

import { ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { breakPoints } = helperFunctions;

const hasData = ref(true);
const uploadFile = ref(null);
const removeFile = ref(false);
</script>

<style></style>
