<template>
  <main>
    <form
      @submit.prevent="submit"
      class="rounded-2xl p-2 md:p-4 flex flex-col gap-4 text-xs md:text-sm"
    >
      <div class="flex flex-col">
        <easiTextInput
          placeholder="Enter office address"
          v-model="args.officeAddress"
          :error="errorRules.officeAddress"
          required
        ></easiTextInput>
      </div>

      <div class="flex flex-col gap-2">
        <div
          class="py-2 px-4 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/img-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <span class="mb-2">
                Upload proof of office address e.g. IKEDC, Lawma, etc. (.png,
                jpg)</span
              >

              <div
                v-if="uploadFile && uploadFile.name"
                class="text-center font-bold text-sm flex-col leading-4"
              >
                {{ uploadFile.name }}
              </div>

              <div
                v-else-if="args.proofOfAddress"
                class="text-center font-bold text-sm flex-col leading-4"
              >
                {{ getLink(args.proofOfAddress) }}
              </div>
            </div>
          </div>

          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              :imageLink="args.proofOfAddress"
              @fileUrl="uploadFile = $event"
            />
          </span>
        </div>
      </div>

      <div
        class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
      >
        <easiButton
          @click="() => $emit('saved')"
          :block="breakPoints('md')"
          variant="text"
          color="secondary"
          type="button"
          size="medium"
          >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
        >

        <div class="flex flex-col md:flex-row md:items-center md:gap-4">
          <easiButton variant="outlined" size="medium" class="hidden md:flex"
            >Save Update</easiButton
          >
          <easiButton :block="breakPoints('md')" size="medium"
            >Submit</easiButton
          >
        </div>
      </div>
    </form>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import banks from "@/utils/banks";
import UploadComponent from "@/components/global/UploadComponent";
import { computed, reactive, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { uploadFileToServer, breakPoints } = helperFunctions;
const store = useDataStore();
const { mutate } = store;

const emit = defineEmits(["saved"]);

const getKyc = computed(() =>
  store.getCompanyKyc &&
  store.getCompanyKyc.data &&
  store.getCompanyKyc.data.companyAddress
    ? store.getCompanyKyc.data.companyAddress
    : {}
);

const bankOptions = computed(() => {
  return banks.map((el) => {
    return {
      label: el.name,
      value: el.name,
    };
  });
});

const uploadFile = ref(null);
const loading = ref(false);

const args = reactive({
  proofOfAddress: null,
  officeAddress: null,
});

const errorRules = reactive({
  proofOfAddress: false,
  officeAddress: false,
});

function validate() {
  if (!uploadFile.value && !args.proofOfAddress) {
    toast.warning("Document is required");
    return false;
  }
  return true;
}

async function submit() {
  loading.value = true;
  try {
    if (validate()) {
      if (uploadFile.value) {
        args.proofOfAddress = await uploadFileToServer(uploadFile.value);
      }

      if (typeof args.proofOfAddress !== "string") {
        toast.error("Failed to upload");
        return;
      }
      let res = await mutate({
        endpoint: "CreateCompanyAddressVerification",
        service: "SETTINGS",
        data: {
          input: args,
        },
      });
      if (res && res.success) {
        toast.success(res.message);
        emit("saved");
      } else if (res && !res.success) {
        toast.error(res.message);
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

if (getKyc.value) {
  args.proofOfAddress = getKyc.value.proofOfAddress;
  args.officeAddress = getKyc.value.officeAddress;
}

function getLink(url) {
  if (url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  }
}
</script>

<style></style>
