<template>
  <div class="flex flex-col gap-4">
    <div
      class="grid grid-cols-1 md:grid-cols-1 bg-newGrey rounded-2xl md:rounded-3xl p-2 md:p-4 items-start gap-2 md:gap-4 text-xs md:text-sm"
    >
      <easiTextCard
        title="Office address"
        :value="getKyc && getKyc.officeAddress"
      />

      <easiTextCard
        fileSize="2mb"
        :value="getKyc && getLink(getKyc.proofOfAddress)"
        :url="getKyc && getKyc.proofOfAddress"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();

const getKyc = computed(() =>
  store.getCompanyKyc &&
  store.getCompanyKyc.data &&
  store.getCompanyKyc.data.companyAddress
    ? store.getCompanyKyc.data.companyAddress
    : {}
);
function getLink(url) {
  if (url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  }
}
const personalData = computed(() => {
  return {
    firstName: "Yogheneochukwu",
    lastName: "Obasanha",
    email: "ochukwo@myeazipay.com",
    phone: "08160178711",
    address: "29 Iyanfoworogi str. JInadu, Lagos state.",
    state: "Lagos",
    country: "Nigeria",
    gender: "Male",
    dateOfBirth: "06/09/91",
    placeOfBirth: "Alimosho, Lagos State, Nigeria.",
  };
});
</script>

<style></style>
