<template>
  <div class="flex flex-col gap-3">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize border border-dark-100 rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div class="flex items-center gap-2 md:gap-4">
        <h2 class="md:text-lg text-xs font-medium">Company Details</h2>
        <GetStatus v-if="getKyc" :status="getKyc && getKyc.status" />
      </div>

      <div class="flex md:flex-row flex-col items-center gap-4">
        <easiButton
          v-if="
            (getKyc &&
              getKyc.status &&
              getKyc.status !== 'verified' &&
              getKyc.status !== 'pending') ||
            hasData
          "
          @click="hasData = false"
          variant="outlined"
          size="small"
          >Edit</easiButton
        >

        <div>
          <slot />
        </div>
      </div>
    </div>

    <ViewPersonal v-if="getKyc && hasData" />
    <EditPersonal @saved="hasData = !hasData" v-else />

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import ViewPersonal from "./View.vue";
import EditPersonal from "./Edit.vue";
import GetStatus from "../GetStatus.vue";
import { useDataStore } from "@/stores/data.js";

import { computed, ref, onMounted } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { breakPoints } = helperFunctions;

const store = useDataStore();
const { query } = store;

const loading = ref(false);
const hasData = ref(true);
const status = ref("Verified");
const getKyc = computed(() =>
  store.getCompanyKyc &&
  store.getCompanyKyc.data &&
  store.getCompanyKyc.data.companyAccountInformation
    ? store.getCompanyKyc.data.companyAccountInformation
    : {}
);

const queryKyc = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetCompanyKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "companyKyc",
    });
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await queryKyc();
});
</script>

<style></style>
