<template>
  <div class="flex flex-col gap-6">
    <div
      class="flex items-center justify-between gap-4 uppercase md:capitalize rounded-md p-2 md:p-0 md:rounded-none md:border-none"
    >
      <div class="flex items-center gap-2 md:gap-4">
        <h2 class="md:text-lg text-xs font-medium">Document Upload</h2>
        <GetStatus
          v-if="
            hasData &&
            getKyc &&
            getKyc.companyDocuments &&
            getKyc.companyDocuments.length
          "
          :status="generalVerification ? 'verified' : 'pending'"
          :bgWhite="false"
        />
      </div>
      <div class="flex md:flex-row flex-col items-center gap-4">
        <easiButton
          v-if="
            (!generalVerification &&
              getKyc.companyDocuments &&
              getKyc.companyDocuments.length) ||
            hasData
          "
          @click="hasData = false"
          variant="outlined"
          size="small"
          >Edit</easiButton
        >

        <div>
          <slot />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <div
        v-for="file in formFile"
        :key="file.docKey"
        class="py-2 px-4 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <div
            class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
          >
            <img
              v-show="!file.docType"
              src="@/assets/icons/pdf-icon.svg"
              alt=""
            />
            <img
              v-show="file.docType"
              src="@/assets/icons/img-icon.svg"
              alt=""
            />
          </div>
          <div class="flex flex-col leading-4">
            <span class="mb-2"> {{ file.label }}</span>

            <div
              v-if="file.uploadFile && file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ file.uploadFile.name }}
            </div>

            <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div>
          </div>
        </div>

        <GetStatus v-if="status" :status="'Verified'" :showBorder="false" />
        <span v-else class="bg-white rounded-full">
          <UploadComponent
            :label="'Upload'"
            :showBorder="false"
            :status="file.status && hasData ? file.status : ''"
            :loop="true"
            :imageLink="
              typeof file.uploadFile === 'string' ? file.uploadFile : ''
            "
            @fileUrl="file.uploadFile = $event"
          />
        </span>
      </div>

      <div
        class="py-4 px-2 md:px-4 border border-dark-100 rounded-xl bg-white flex items-center justify-between"
      >
        <div class="flex flex-col gap-2">
          <div
            class="flex font-bold md:text-base text-xs flex-col text-left leading-4"
          >
            Company directors/proprietors
          </div>
          <span class="text-xs"
            >Containing information about the business directors and
            shareholders.</span
          >

          <span v-if="directorsFile && directorsFile.name" class="text-xs">{{
            directorsFile.name
          }}</span>

          <span
            v-else-if="directorsFile && !directorsFile.name"
            class="text-xs font-bold"
            >{{ directorsFile }}</span
          >
        </div>

        <GetStatus
          v-if="directorStatus && hasData"
          :status="directorStatus"
          :showBorder="true"
        />

        <span v-else class="bg-white rounded-full">
          <UploadComponent
            :label="'Upload'"
            :loop="true"
            :imageLink="typeof directorsFile === 'string' ? directorsFile : ''"
            @fileUrl="directorsFile = $event"
          />
        </span>
      </div>
    </div>

    <div
      v-if="!hasData || !generalVerification"
      class="flex w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
    >
      <easiButton
        :block="breakPoints('md')"
        @click="hasData = true"
        variant="text"
        color="secondary"
        size="medium"
        >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
      >

      <div class="flex flex-col md:flex-row md:items-center md:gap-4">
        <easiButton
          @click="submit"
          variant="outlined"
          size="medium"
          class="hidden md:flex"
          >Save Update</easiButton
        >
        <easiButton :block="breakPoints('md')" size="medium" @click="submit"
          >Submit</easiButton
        >
      </div>
    </div>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import GetStatus from "../GetStatus.vue";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

import { computed, reactive, ref, onMounted } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { breakPoints, uploadFileToServer } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const loading = ref(false);
const hasData = ref(true);
const directorsFile = ref(null);
const status = ref(null);

const getKyc = computed(() =>
  store.getCompanyKyc && store.getCompanyKyc.data
    ? store.getCompanyKyc.data
    : {}
);
const generalVerification = computed(() => {
  if (
    getKyc.value &&
    getKyc.value.companyDocuments &&
    getKyc.value.companyDocuments.length
  ) {
    const all = getKyc.value.companyDocuments.find(
      (doc) => doc.status !== "verified" || doc.status !== "pending"
    );
    if (!all) return false;
  }
  return true;
});

const queryKyc = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetCompanyKycInformation",
      payload: {},
      service: "SETTINGS",
      storeKey: "companyKyc",
    });
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const identityDoc = computed(() => {
  return {
    documentType: "National ID Card",
    document: null,
  };
});

const documentType = ref("");
const args = ref([]);

const Options = ref([
  {
    label: "National ID",
    value: "National Identification Number",
  },
  {
    label: "Voters Card",
    value: "Voters Card",
  },
  {
    label: "Drivers License",
    value: "Drivers License",
  },
  {
    label: "International Passport",
    value: "International Passport",
  },
]);

const formFileLtd = ref([
  {
    label: "6 month bank statement",
    uploadFile: null,
    docKey: "six_months_bank_statement",
    status: null,
  },
  {
    label: "Form C02",
    uploadFile: null,
    docKey: "form_c02",
    status: null,
  },
  {
    label: "Form C07",
    uploadFile: null,
    docKey: "form_c07",
    status: null,
  },
  { label: "MEMART", uploadFile: null, docKey: "memart", status: null },
  {
    label: "Upload CAC Document",
    uploadFile: null,
    docKey: "cac_document",
    status: null,
  },
]);

const formFileNgo = ref([
  {
    label: "Upload proof of office address e.g. IKEDC, Lawma, etc. (.png, jpg)",
    uploadFile: null,
    docKey: "proofOfAddress",
    docType: "img",
    status: null,
  },
  {
    label: "Corporate Affairs Commission (CAC Certification)",
    uploadFile: null,
    docKey: "cac_document",
    status: null,
  },
  {
    label: "6 month bank statement",
    uploadFile: null,
    docKey: "six_months_bank_statement",
    status: null,
  },
  {
    label: "Incorporated Trustee document",
    uploadFile: null,
    docKey: "trusteeDocument",
    status: null,
  },
  {
    label: "NGO Constitution",
    uploadFile: null,
    docKey: "ngoConstitution",
    status: null,
  },
]);

const formFile = computed(() =>
  getKyc.value &&
  getKyc.value.companyAccountInformation &&
  getKyc.value.companyAccountInformation.businessType ===
    "limited_liability_company"
    ? formFileLtd.value
    : formFileNgo.value
);
async function processItemsWithAsyncFunction(items) {
  let payload = [];
  for (const item of items) {
    try {
      if (item.uploadFile && item.docKey) {
        payload.push({
          name: item.docKey,
          document:
            typeof item.uploadFile == "string"
              ? item.uploadFile
              : await uploadFileToServer(item.uploadFile),
        });
      }
    } catch (error) {
      console.error("Error processing item:", error);
    }
  }
  return payload;
}

function validate() {
  const nullVal = formFile.value.find((fil) => fil.uploadFile == null);
  if (nullVal || !directorsFile.value) {
    console.log("waen");
    toast.warning("Upload all documents to continue");
    return false;
  }
  return true;
}

async function submit() {
  loading.value = true;
  try {
    if (validate()) {
      let data = await processItemsWithAsyncFunction(formFile.value);
      const document = await uploadFileToServer(directorsFile.value);
      args.value = [...data, { name: "companyDirectors", document }];
      let res = await mutate({
        endpoint: "CreateCompanyDocuments",
        service: "SETTINGS",
        data: {
          input: args.value,
        },
      });
      if (res.success) {
        await queryKyc();
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}
function mergeObjectsById(arr1, arr2) {
  return arr1.map((item1) => {
    const matchingItem = arr2.find((item2) => item2.name === item1.docKey);
    return matchingItem
      ? {
          ...item1,
          uploadFile: matchingItem.document,
          status: matchingItem.status,
        }
      : item1;
  });
}

const directorStatus = ref(null);
if (getKyc.value && getKyc.value.companyDocuments) {
  if (
    getKyc.value &&
    getKyc.value.companyAccountInformation &&
    getKyc.value.companyAccountInformation.businessType ===
      "limited_liability_company"
  ) {
    formFileLtd.value = mergeObjectsById(
      formFileLtd.value,
      getKyc.value.companyDocuments
    );
  } else {
    formFileNgo.value = mergeObjectsById(
      formFileNgo.value,
      getKyc.value.companyDocuments
    );
  }
  const data = getKyc.value.companyDocuments.find(
    (doc) => doc.name === "companyDirectors"
  );
  if (data) {
    directorsFile.value = data.document;
    directorStatus.value = data.status;
  }
}
function getLink(url) {
  if (url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  }
}
onMounted(async () => {
  await queryKyc();
});
</script>

<style></style>
