<template>
  <main class="w-full">
    <form
      @submit.prevent="submit"
      class="rounded-2xl md:rounded-3xl p-2 md:p-4 flex flex-col md:gap-x-4 text-xs md:text-sm"
    >
      <div
        class="grid grid-cols-1 md:grid-cols-1 items-center gap-6 mt-4 md:mt-2"
      >
        <div>
          <easiSelectInput2
            :options="companyType"
            :value="args.businessType"
            @update="args.businessType = $event"
            placeholder="Kindly select business type"
            :error="errorRules.businessType"
          ></easiSelectInput2>
        </div>
        <div>
          <easiSelectInput2
            :options="[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]"
            :value="args.isCompanyRegistered"
            @update="args.isCompanyRegistered = $event"
            placeholder="Is your company registered?"
            :error="errorRules.isCompanyRegistered"
          ></easiSelectInput2>
        </div>
      </div>

      <div
        v-if="args.isCompanyRegistered"
        class="py-2 px-4 mt-5 border border-dark-100 rounded-xl bg-newGrey flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <div
            class="h-12 w-12 bg-white rounded-full flex justify-center items-center flex-shrink-0"
          >
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.93548 0H16.8319L24 6.4505V26.25C24 27.2168 23.133 28 22.0645 28H1.93548C0.867053 28 0 27.2168 0 26.25V1.74999C0 0.783139 0.867154 0 1.93548 0Z"
                fill="white"
              />
              <path
                d="M1.93548 0.5H16.6401L23.5 6.6732V26.25C23.5 26.8944 22.9056 27.5 22.0645 27.5H1.93548C1.09446 27.5 0.5 26.8944 0.5 26.25V1.74999C0.5 1.10564 1.09454 0.5 1.93548 0.5Z"
                stroke="#E8E9EB"
              />
              <path
                d="M23.6123 6.64961H18.3705C17.4177 6.64961 16.6445 5.95123 16.6445 5.09061V0.349609"
                stroke="#E8E9EB"
              />
              <path
                d="M7.24777 20.9998C7.07269 20.9998 6.90465 20.9519 6.76126 20.8616C6.23752 20.5305 6.16708 20.162 6.20029 19.911C6.29185 19.2208 7.30462 18.4983 9.21142 17.7623C9.9681 16.3648 10.6881 14.643 11.1172 13.2044C10.6151 12.2835 10.1271 11.0887 10.4828 10.3879C10.6076 10.1424 10.763 9.95414 11.0533 9.87273C11.168 9.84051 11.4578 9.7998 11.5645 9.7998C11.818 9.7998 12.0409 10.075 12.1989 10.2446C12.3473 10.404 12.6839 10.7419 12.0112 13.1285C12.6894 14.3089 13.6504 15.5113 14.5711 16.3347C15.2307 16.2342 15.7982 16.1829 16.2605 16.1829C17.0484 16.1829 17.5259 16.3377 17.7206 16.6565C17.8816 16.9202 17.8157 17.2285 17.5243 17.5723C17.2441 17.9026 16.8577 18.0773 16.4074 18.0773C15.7957 18.0773 15.0832 17.7517 14.2888 17.1085C12.8615 17.3599 11.1947 17.8085 9.84735 18.305C9.42675 19.0571 9.02376 19.663 8.64843 20.1073C8.13274 20.7157 7.68799 20.9998 7.24777 20.9998ZM8.58706 18.8265C7.5119 19.3357 7.07369 19.7541 7.042 19.9899C7.03696 20.0289 7.02338 20.1315 7.25884 20.2833C7.3338 20.2633 7.77151 20.095 8.58706 18.8265ZM15.448 16.9431C15.858 17.209 15.9582 17.3434 16.2263 17.3434C16.344 17.3434 16.6796 17.3391 16.8351 17.1564C16.91 17.0678 16.9392 17.0109 16.9508 16.9804C16.8889 16.9529 16.8069 16.8969 16.3596 16.8969C16.1056 16.8973 15.7861 16.9066 15.448 16.9431ZM11.6898 14.1524C11.33 15.2014 10.8551 16.3338 10.3444 17.3595C11.3959 17.0156 12.539 16.7154 13.6126 16.503C12.9334 15.8382 12.2547 15.008 11.6898 14.1524ZM11.3844 10.5647C11.3351 10.5787 10.7152 11.3096 11.4327 11.9282C11.9101 11.0315 11.406 10.5587 11.3844 10.5647Z"
                fill="#E2574C"
              />
            </svg>
          </div>
          <div class="flex flex-col leading-4">
            <span class="mb-2">
              Upload CAC Registration Certificate (10mb max)</span
            >
            <div
              v-if="uploadFile && uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ uploadFile.name }}
            </div>

            <div
              v-else-if="args.cacRegistration"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(args.cacRegistration) }}
            </div>
          </div>
        </div>

        <span class="bg-white rounded-full">
          <UploadComponent
            :label="'Upload'"
            :showBorder="false"
            :loop="true"
            :imageLink="args.cacRegistration"
            @fileUrl="uploadFile = $event"
          />
        </span>
      </div>

      <div
        class="flex mt-6 w-full flex-col-reverse md:flex-row md:items-center gap-4 justify-between"
      >
        <easiButton
          :block="breakPoints('md')"
          type="button"
          variant="text"
          color="secondary"
          size="medium"
          @click="() => $emit('saved')"
          >{{ breakPoints("md") ? "Discard Changes" : "Cancel" }}</easiButton
        >

        <div class="flex flex-col md:flex-row md:items-center md:gap-4">
          <easiButton variant="outlined" size="medium" class="hidden md:flex"
            >Save Update</easiButton
          >
          <easiButton :block="breakPoints('md')" size="medium"
            >Submit</easiButton
          >
        </div>
      </div>
    </form>
    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

import { computed, reactive, ref } from "vue";

const { uploadFileToServer, breakPoints } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const emit = defineEmits(["saved"]);

const uploadFile = ref(null);
const loading = ref(null);
const getKyc = computed(() =>
  store.getCompanyKyc &&
  store.getCompanyKyc.data &&
  store.getCompanyKyc.data.companyAccountInformation
    ? store.getCompanyKyc.data.companyAccountInformation
    : {}
);
const companyType = ref([
  {
    label: "Limited Liability Company (Ltd)",
    value: "limited_liability_company",
  },
  {
    label: "Non-Governmental Organization (NGO)",
    value: "non_governmental_organization",
  },
]);
const args = reactive({
  isCompanyRegistered: null,
  cacRegistration: null,
  businessType: null,
});

const errorRules = reactive({
  businessType: false,
  isCompanyRegistered: false,
});

function validate() {
  if (!args.businessType) {
    errorRules.businessType = "Field is required";
    return false;
  }
  if (!args.isCompanyRegistered) {
    errorRules.isCompanyRegistered = "Field is required";
    return false;
  }
  if (!uploadFile.value && !args.cacRegistration) {
    toast.warning("CAC document is required");
    return false;
  }
  return true;
}

async function submit() {
  loading.value = true;
  try {
    if (validate()) {
      if (uploadFile.value) {
        args.cacRegistration = await uploadFileToServer(uploadFile.value);
      }
      if (typeof args.cacRegistration !== "string") {
        toast.error("File upload failed");
        return;
      }
      let res = await mutate({
        endpoint: "CreateCompanyInformation",
        service: "SETTINGS",
        data: {
          input: args,
        },
      });
      if (res && res.success) {
        toast.success(res.message);
        emit("saved");
      } else if (res && !res.success) {
        toast.error(res.message);
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

function getLink(url) {
  if (url) {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    return filename;
  }
}

if (getKyc.value) {
  args.businessType = getKyc.value.businessType;
  args.cacRegistration = getKyc.value.cacRegistration;
  args.isCompanyRegistered = getKyc.value.isCompanyRegistered;
}
</script>

<style></style>
